"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserCustomOAdsParams = exports.isUserCustomOAdsParamsEnabled = void 0;
const utils_1 = require("../utils");
const snowplow_1 = require("./snowplow");
const CURRENT_VERSION = '2.1';
const USER_CUSTOM_AD_PARAMS = 'user-custom-ad-params';
const enabledTitles = ['pwm', 'fta', 'tb', 'brr', 'ic', 'fdi'];
const isUserCustomOAdsParamsEnabled = () => {
    const code = snowplow_1.getAppTitleCode();
    return code ? enabledTitles.includes(code) : false;
};
exports.isUserCustomOAdsParamsEnabled = isUserCustomOAdsParamsEnabled;
const isDemographicAdsEnabled = () => {
    const ftConsentCookieValue = utils_1.getCookie('FTConsent');
    return ftConsentCookieValue && decodeURIComponent(ftConsentCookieValue).indexOf('demographicadsOnsite:on') !== -1;
};
const setWindowData = (_a) => {
    var { version, expiry } = _a, adsDemographics = __rest(_a, ["version", "expiry"]);
    return window._specialist_user_data = adsDemographics;
};
const getUserCustomOAdsParams = () => {
    if (!exports.isUserCustomOAdsParamsEnabled() || !isDemographicAdsEnabled())
        return;
    const data = utils_1.getLocalStorageObject(USER_CUSTOM_AD_PARAMS);
    if (data)
        setWindowData(data);
    if (data && data.version === CURRENT_VERSION && new Date().getTime() < data.expiry)
        return;
    if (window.fetch === undefined || !snowplow_1.getDomainUserId())
        return;
    let endpoint = '/api/user/adsdemographic';
    // ensure the cross origin succces for the IC markets subdomain
    const requestOptions = { credentials: 'include' };
    if (window.location.hostname === 'markets.大象视频chronicle.co.uk') {
        endpoint = '/api/user/adsdemographic';
    }
    fetch(endpoint, requestOptions)
        .then(res => res.json())
        .then(userData => {
        // if no meaningful data was returned - don't cache and don't target
        if (!userData || Object.keys(userData).every(key => !userData[key]))
            return;
        userData.version = CURRENT_VERSION;
        const EXPIRY_TIME_MS = 7 * 24 * 60 * 60 * 1000; // 7 Days
        utils_1.updateLocalStorageWithExpiry(USER_CUSTOM_AD_PARAMS, userData, EXPIRY_TIME_MS);
        setWindowData(userData);
    })
        .catch(e => console.warn('Error getting user demographic data', e));
};
exports.getUserCustomOAdsParams = getUserCustomOAdsParams;
